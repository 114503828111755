import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: 0.5
});

export const handleError = handleErrorWithSentry();
